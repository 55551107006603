let $ = require("../jquery");
module.exports = function (_arguments) {
    let args = _arguments;
    if (_arguments.widget) {
        window.widgetSlideDown = {};
        let iSlidedownObj = window.widgetSlideDown,
            SlideDown = function(currentWidget) {
                this.widget = currentWidget;
                this.switcherClassName = args.switcherClassName || ".slidedown-activate";
                this.hiddenClassName = args.hiddenClassName || ".slidedown-hidden";
                this.closeSiblings = args.closeSiblings || false;
            };

        SlideDown.prototype.hide = function () {
            this.widget.find(this.hiddenClassName).slideUp();
            this.widget.removeClass("collapsed");
        };
        SlideDown.prototype.show = function () {
            this.widget.find(this.hiddenClassName).slideDown();
            this.widget.addClass("collapsed");

            let eventName = this.widget[0].classList[0]+":show";
            let ShowEvent = new Event(eventName, {bubbles: true});
            this.widget[0].dispatchEvent(ShowEvent);
        };


        $.each(_arguments.widget, function (index) {

            let currentWidget = $(this),
                alias = currentWidget.attr("class").replace(new RegExp("-", 'g'), "_").replace(new RegExp(" ", 'g'), "_");
            if (!iSlidedownObj[alias]) {
                iSlidedownObj[alias] = {}
            }
            let  createdObject = iSlidedownObj[alias][index] = new SlideDown(currentWidget);

            currentWidget.find(createdObject.switcherClassName).on("click", function () {
                if (currentWidget.hasClass("collapsed")) {
                    createdObject.hide();

                } else {
                    createdObject.show();
                    if (args.closeSiblings) {
                        $.each(iSlidedownObj[alias], function(ind){
                            if (ind!=index) {
                                this.hide();
                            }
                        })
                    }
                }
            });
        });
    } else {
        throw new Error("melissa.slidedown.init: init new slidedown failed - no object specified. specify $ object in arguments like: {widget:$('selector')}")
    }
};
