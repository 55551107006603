"use strict";
require("babel-polyfill");
var autoForms = require("./autoforms");
var maskedInput = require("./maskedinput");
var $ = require("./jquery");
var ajaxForm = require("./forms");
var tabs = require("./es6/tabs");
var gmapObjects = require("./es6/gmapObjects");
var sliderUnified = require("./es6/sliderUnified");
var trigger = require("./es6/trigger");
var slidedown = require("./es6/slidedown");
var ScrollMagic = require("../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic");

require('../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap');
var gsap = require("gsap");
var TweenMax = require("../../../node_modules/gsap/src/uncompressed/TweenMax");
var TimelineMax = require("../../../node_modules/gsap/src/uncompressed/TimelineMax");
var metrika = require("./es6/metrika")(39717505);
!window.addEventListener && (function (WindowPrototype, DocumentPrototype, ElementPrototype, addEventListener, removeEventListener, dispatchEvent, registry) {
    WindowPrototype[addEventListener] = DocumentPrototype[addEventListener] = ElementPrototype[addEventListener] = function (type, listener) {
        var target = this;

        registry.unshift([target, type, listener, function (event) {
            event.currentTarget = target;
            event.preventDefault = function () { event.returnValue = false };
            event.stopPropagation = function () { event.cancelBubble = true };
            event.target = event.srcElement || target;

            listener.call(target, event);
        }]);

        this.attachEvent("on" + type, registry[0][3]);
    };

    WindowPrototype[removeEventListener] = DocumentPrototype[removeEventListener] = ElementPrototype[removeEventListener] = function (type, listener) {
        for (var index = 0, register; register = registry[index]; ++index) {
            if (register[0] == this && register[1] == type && register[2] == listener) {
                return this.detachEvent("on" + type, registry.splice(index, 1)[0][3]);
            }
        }
    };

    WindowPrototype[dispatchEvent] = DocumentPrototype[dispatchEvent] = ElementPrototype[dispatchEvent] = function (eventObject) {
        return this.fireEvent("on" + eventObject.type, eventObject);
    };
})(Window.prototype, HTMLDocument.prototype, Element.prototype, "addEventListener", "removeEventListener", "dispatchEvent", []);
if (!document.querySelectorAll) {
    document.querySelectorAll = function (selectors) {
        var style = document.createElement('style'), elements = [], element;
        document.documentElement.firstChild.appendChild(style);
        document._qsa = [];

        style.styleSheet.cssText = selectors + '{x-qsa:expression(document._qsa && document._qsa.push(this))}';
        window.scrollBy(0, 0);
        style.parentNode.removeChild(style);

        while (document._qsa.length) {
            element = document._qsa.shift();
            element.style.removeAttribute('x-qsa');
            elements.push(element);
        }
        document._qsa = null;
        return elements;
    };
}

if (!document.querySelector) {
    document.querySelector = function (selectors) {
        var elements = document.querySelectorAll(selectors);
        return (elements.length) ? elements[0] : null;
    };
}
    function i(blockName, callbackFunction) {
        callbackFunction([].slice.call(document.querySelectorAll(blockName)));
    }

    function initMasks() {
        i('input[data-field-type="maskphone"]', function (nodes) {
            nodes.forEach(function (node) {
                maskedInput(node).mask("+7 (999) 999-99-99");
            });
        });
    }

    window.APP = {
        init: function () {
            i(".sbpr-b_form", function (nodes) {
                nodes.forEach(function (node) {
                    try {
                        autoForms.init(node, {
                            Validators: {
                                "maskphone": {
                                    "keys": "1234567890",
                                    "errorMessage": "Type only numbers",
                                    "validatorFunction": function validatorFunction(field) {
                                        return field.valid = field._node.value.indexOf("_") < 0;
                                    },
                                    "keypressValidatorFunction": false
                                }
                            }
                        });
                    } catch (e) {
                        console.log("autoforms init error")
                    }
                });

                ajaxForm(".sbpr-b_form");
            });

            /**
             * Google Maps init
             */
            gmapObjects.init("map","/app/frontend/scripts/objects.json", 60.053103, 30.819397, 9);

            /**
             * Tabs init
             */
            tabs({
                widget: document.querySelectorAll(".spbr-b_tabs"),
                options: {
                    switcherClassName: "spbr-b_tabs-switch",
                    tabClassName: "spbr-b_tab",
                    callbackAfterCreate: function (widget) {
                        widget.addEventListener("tabs:click", function (event) {
                            $("html, body").animate({
                                scrollTop: widget.offsetTop-120
                            }, 300);
                            metrika.reachGoal("advantages").then(function () {
                                console.log("metrika -> goal -> sent: advantages");
                            });
                        })
                    }
                }
            });

            /**
             * iBox init
             */
            require("./es5/ibox")(".i-box_gallery");

            /**
             * Sliders init
             */
            sliderUnified({
                widget: document.querySelectorAll(".spbr-b_gallery-control-slides"),
                options: {
                    amountToSlide: 1,
                    cyclic: false,
                    autoSlide: false,
                    viewportLength: 7
                }
            });

            /**
             * Init masks
             */
            initMasks();

            /**
             * slide down (qa) block
             */

            i(".spbr-b_hide-show-item", function (widget) {
                slidedown({
                    widget: widget,
                    switcherClassName: ".hide-show-activator",
                    hiddenClassName: ".hide-show-content",
                    closeSiblings: true
                });
                document.addEventListener("spbr-b_hide-show-item:show", function () {
                    metrika.reachGoal("question").then(function () {
                        console.log("metrika -> goal -> sent: question");
                    });
                });
            });

            /**
             * sticky menu
             */

            window.addEventListener("scroll", function(event) {
                var header = document.querySelector(".spbr-b_header-middle");

                if (header.offsetHeight - window.pageYOffset+19<=0) {
                    header.classList.add("attached");
                } else {
                    header.classList.remove("attached");
                }
            });

            /*i(".spbr-e_menu-link-stiky", function (nodes) {
             nodes.forEach(function (node) {
             document.querySelector(".header-menu_menu-item[href=\"#"+node.attributes.name.value+"\"]")
             .addEventListener("click", function (event) {
             metrika.reachGoal("menu",{title: node.attributes.name.value}).then(function () {
             console.log("metrika -> goal -> sent: menu");
             });
             i(".spbr-b_header-menu .header-menu_menu-item", function (nodes) {
             nodes.forEach(function (node) {
             node.classList.remove("current");
             });
             });
             document.querySelector(".header-menu_menu-item[href=\"#"+node.attributes.name.value+"\"]").classList.add("current");
             });
             trigger({
             widget: node,
             type: "reach",
             triggerAfterEventFiresCount: -1,
             callback: function () {
             i(".spbr-b_header-menu .header-menu_menu-item", function (nodes) {
             nodes.forEach(function (node) {
             node.classList.remove("current");
             })
             });

             document.querySelector(".header-menu_menu-item[href=\"#"+node.attributes.name.value+"\"]").classList.add("current")
             }
             })
             })
             });*/
            i(".spbr-b_header-menu", function(widget) {
                $.each($(widget).find("a"), function(){
                    var thisLink = $(this),
                        thisLinkPlace = $("a[name='"+thisLink.attr("href").replace("#","")+"']");

                    thisLink.on("click", function(evt){
                        evt.preventDefault();
                        $('html, body').animate({
                            scrollTop: thisLinkPlace.offset().top
                        }, 500,function(){
                            $(widget).find(".header-menu_menu-item").removeClass("current");
                            thisLink.addClass("current");
                        });
                    });
                    trigger({
                        widget: thisLinkPlace[0],
                        type: "reach",
                        triggerAfterEventFiresCount: -1,
                        callback: function () {
                            $(widget).find(".header-menu_menu-item").removeClass("current");
                            thisLink.addClass("current");
                        }
                    });

                    // console.log(thisLinkPlace);
                });
            });

            i("a[name]", function(widget){
                $.each(widget, function(){
                    var thisLink = $(this);
                    $("a[href='#"+thisLink.attr("name")+"']").on("click", function(e){
                        e.preventDefault();
                        $('html, body').animate({
                            scrollTop: thisLink.offset().top
                        }, 500);
                    });
                })
            });

            /**
             * video
             */

            require("./es5/ivideo")(".spbr-b_video");
            document.addEventListener("ivideo:popup", function () {
                metrika.reachGoal("video").then(function () {
                    console.log("metrika -> goal -> sent: video");
                });
            });

            /**
             * Objects
             */

            require("./es5/ibox")(".spbr-b_hidden-gallery", $(".spbr-b_google-map"));
            i(".spbr-b_open-gallery-marker", function (nodes) {
                nodes.forEach(function (node) {
                    node.addEventListener("click", function () {
                        nodes.forEach(function (item) {
                            item.classList.remove("active");
                        });
                        node.classList.add("active");
                        $("html, body").animate({
                            scrollTop: $(".spbr-b_google-map")[0].offsetTop-120
                        }, 300);
                        metrika.reachGoal("object").then(function () {
                            console.log("metrika -> goal -> sent: object");
                        });
                        var elem = document.getElementById(node.dataset.id).querySelector(".i-box_gallery-item");

                        var clickEvent = new MouseEvent("click");
                        elem.dispatchEvent(clickEvent);
                    })
                })
            });

            /**
             * Products
             */
            i(".spbr-b_product-pp", function (nodes) {
                nodes.forEach(function (node) {
                    var title = node.querySelector(".product-pp_title");
                    var wrap = node.querySelector(".spbr-b_product-pp-wrap");

                    title.addEventListener("click", function (evt) {
                        evt.stopPropagation();

                        i(".show-desc", function (nodes) {
                            nodes.forEach(function (node) {
                                node.classList.remove("show-desc");
                            })
                        });

                        node.classList.add("show-desc");
                        metrika.reachGoal("description").then(function () {
                            console.log("metrika -> goal -> sent: description");
                        });
                    });

                    wrap.addEventListener("click", function () {
                        i(".show-desc", function (nodes) {
                            nodes.forEach(function (node) {
                                node.classList.remove("show-desc");
                            })
                        });
                    });
                })
            });

            /**
             * animation
             */

            i(".spbr-b_roof-sandwich-anim", function (nodes) {
                var node = nodes[0];
                // init controller
                var controller = new ScrollMagic.Controller();

                var tl = new TimelineMax({
                    tweens: [
                        new TweenMax.to("#roof-item_2", 1, {y: -170, ease: Linear.easeNone}),
                        new TweenMax.to("#roof-item_3", 1, {y: -347, ease: Linear.easeNone}),
                        new TweenMax.to("#roof-item_4", 1, {y: -506, ease: Linear.easeNone}),
                        new TweenMax.to("#roof-item_5", 1, {y: -665, ease: Linear.easeNone}),
                        new TweenMax.set(".hidden-text", {className: "hidden-text hidden", delay: 0.6})
                    ]
                });

                tl.set(".sizes", {className: "sizes visible"});

                // create a scene
                new ScrollMagic.Scene({
                    duration: 900,    // the scene should last for a scroll distance of 500px
                    triggerElement: "#sandwich-anim_trigger"
                })
                    .setTween(tl)
                    .triggerHook(0.28)
                    .setPin("#sandwich-anim_root") // pins the element for the the scene's duration
                    .addTo(controller); // assign the scene to the controller
            });

            /**
             *
             */
            i(".spbr-e_plenka-trigger", function (nodes) {
                nodes[0].addEventListener("click", function (event) {
                    document.querySelector(".spbr-b_plenka-layer").classList.add("opened");
                });
            });

            document.querySelector(".spbr-b_plenka-layer").addEventListener("click", function () {
               this.classList.remove("opened");
            })

        }
    };

    window.APP.init();