var $ = require("./jquery");
var metrika = require("./es5/metrika")(39717505);

module.exports = function(selector) {
    $.each($(selector), function(){
        var newFormObj = $(this);
        newFormObj.find("input[type='submit']").on("click", function(e){
            var formData = new FormData(newFormObj[0]);
            e.preventDefault();
            if (!newFormObj.hasClass("form-sending")) {
                $.ajax({
                    url: newFormObj.attr("action"),
                    type:'POST',
                    processData: false,
                    contentType: false,
                    data: formData,
                    beforeSend: function () {
                        newFormObj.addClass("form-sending");
                    },
                    success:function(data){
                        var success = newFormObj.find(".success"),
                            fail =    newFormObj.find(".fail");

                        newFormObj.find(".form-answers").on("click",".close", function () {
                            success.fadeOut();
                            fail.fadeOut();
                        });
                        if (data == 200) {

                            metrika.reachGoal(newFormObj.data("metrikagoal")).then(function (res) {
                                console.log(res);
                            });
                            newFormObj.find(".form-content").fadeOut(500, function(){
                                success.fadeIn(500, function(){
                                    setTimeout( function(){
                                        success.fadeOut(500,function(){
                                            newFormObj.find(".form-content").fadeIn(500);
                                            newFormObj.find("input[type='text']").add(newFormObj.find("textarea")).add(newFormObj.find("input[type='file']")).val("");
                                            newFormObj.find(".arm-elements_file-select-input").removeClass("file-attached");
                                            newFormObj.find(".arm-elements_file-select-input").find("file-title").find("span").text("");
                                        });
                                    },8000);
                                });

                            });
                        } else {
                            newFormObj.find(".form-content").fadeOut(500,function(){
                                fail.fadeIn(500, function(){
                                    setTimeout( function(){
                                        fail.fadeOut(500,function(){
                                            newFormObj.find(".form-content").fadeIn(500);
                                        });
                                    },8000);
                                });
                            });
                        }
                    },
                    error: function() {
                        var success = newFormObj.find(".success"),
                            fail =    newFormObj.find(".fail");
                        newFormObj.find(".form-content").fadeOut(500,function(){
                            fail.fadeIn(500, function(){
                                setTimeout( function(){
                                    fail.fadeOut(500,function(){
                                        newFormObj.find(".form-content").fadeIn(500);
                                    });
                                },8000);
                            });
                        });
                        newFormObj.removeClass("form-sending");
                    },
                    compvare: function () {
                        newFormObj.removeClass("form-sending");
                    }
                });
            }
        });
    });
};