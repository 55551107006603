"use strict";
let $ = require("../jquery");
class SliderUnified {
    constructor(widget, options) {
        this.widget = widget;
        this.cyclic = options.cyclic || false;
        this.sliderClassName = options.sliderClassName;
        this.slideClassName = options.slideClassName || ".slider-slide";
        this.amountToSlide = options.amountToSlide || 1;
        this.orientation = options.orientation || "horizontal"; // vertical
        this.currentSlide = 0;
        this.animSpeed = 400;
        this.autoSlide = options.autoSlide || false;
        this.slideChangeDelay = options.slideChangeDelay || 3000;
        this.stopOnHover = options.stopOnHover || true;
        this.viewportLength = options.viewportLength-1 || 0;
        this.enableScroll = options.enableScroll || false;
        this.slideWrap = this.widget.find(options.sliderslideWrapClassname || ".slider-slides");
        this.controlPrev = this.widget.find(options.sliderControlLeftClassName || ".slider-control_left");
        this.controlNext = this.widget.find(options.sliderControlRightClassName || ".slider-control_right");
        this.slides = this.widget.find(this.slideClassName);
    }
    init() {
        let _this = this;
        $.each(_this.slides, function () {
            this.slideOffsetLeft = -$(this).position().left;
            this.slideOffsetTop = -$(this).position().top;
        });
        // Actions
        _this.setSlide(_this.slides[0]);
        _this.controlPrev.on("click", function(){_this.slidePrev();});
        _this.controlNext.on("click", function(){_this.slideNext();});
        if (_this.slides.length<_this.amountToSlide) {
            _this.controlPrev.remove();
            _this.controlNext.remove();
        }
        if (_this.enableScroll) {
            _this.widget.on("wheel", function (evt) {
                evt.preventDefault();
                if (evt.originalEvent.deltaY > 0) {
                    _this.animSpeed = 0;
                    _this.slideNext();
                    _this.animSpeed = 400;
                } else {
                    _this.animSpeed = 0;
                    _this.slidePrev();
                    _this.animSpeed = 400;
                }
            });
        }
        if (_this.autoSlide) {
            setTimeout(function(){
                _this.play();
            },_this.slideChangeDelay);
        }
    }
    setSlide(slide) {
        if (this.orientation == "horizontal") {
            if (slide) {
                this.slideWrap.animate({left: slide.slideOffsetLeft}, this.animSpeed);
            }

        } else {
            this.slideWrap.animate({top: slide.slideOffsetTop}, this.animSpeed);
        }
        this.currentSlide = this.slides.index(slide);
        if (!this.cyclic) {
            if (!this.slides[this.currentSlide + this.amountToSlide + this.viewportLength]) {
                this.controlNext.addClass("disabled");
            } else {
                this.controlNext.removeClass("disabled");
            }
            if (!this.slides[this.currentSlide - this.amountToSlide]) {
                this.controlPrev.addClass("disabled");
            } else {
                this.controlPrev.removeClass("disabled");
            }
            if (this.controlPrev.hasClass("disabled")&&this.controlNext.hasClass("disabled")) {
                this.controlPrev.remove();
                this.controlNext.remove();
            }
        }
    }
    slidePrev() {
        if (this.slides[this.currentSlide - this.amountToSlide]) {
            this.setSlide(this.slides[this.currentSlide - this.amountToSlide]);
        } else {
            if (this.cyclic) {
                this.setSlide(this.slides[this.slides.length - 1]);
            }
        }
    }
    slideNext() {
        if (this.slides[this.currentSlide + this.amountToSlide + this.viewportLength]) {
            this.setSlide(this.slides[this.currentSlide + this.amountToSlide]);
        } else {
            if (this.cyclic) {
                this.setSlide(this.slides[0]);
            }
        }
    }
    play() {
        let thisObj = this;
        if (thisObj.autoSlide) {
            if (thisObj.stopOnHover) {
                if (thisObj.widget.find(":hover").length<1) {
                    this.slideNext();
                }
            } else {
                this.slideNext();
            }
            setTimeout(function(){
                thisObj.play();
            },thisObj.slideChangeDelay);
        }
    };
}

module.exports = function(args) {
        if (args.widget) {
            $.each(args.widget, function () {
                let currentWidget = $(this),
                    createdObject = new SliderUnified(currentWidget, args.options);
                createdObject.init();
            });

        } else {
            throw new Error(`hunter.sliderUnified.init: init new slider failed - no object specified. specify $ object in arguments like: {widget:$("selector")}`);
        }
};