"use strict";

module.exports = function (arg) {
    (function() {
        let args = arg;
        if (args.widget) {
            let triggerCount = 0;
            args.triggerAfterEventFiresCount = args.triggerAfterEventFiresCount|| 1;
            window.addEventListener("scroll", function (event) {

                let widgetMetrics = args.widget.getBoundingClientRect();
                switch(args.type) {
                    case "reach": {
                        if (widgetMetrics.top<(window.outerHeight/2)&&widgetMetrics.top>0) {
                            triggerCount+=1;
                            if (triggerCount === args.triggerAfterEventFiresCount || args.triggerAfterEventFiresCount === -1) {
                                args.callback(event);
                            }
                        }
                    } break;
                    case "over": {
                        if (triggerCount === args.triggerAfterEventFiresCount) {
                            args.callback(event);
                        }
                    } break;
                    default: {
                        if (triggerCount === args.triggerAfterEventFiresCount) {
                            args.callback(event);
                        }
                    } break;
                }
            });
        } else throw new Error("trigger: widget to trigger on not set")
    })();
};