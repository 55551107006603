"use strict";
const DEFAULT_TABS_SWITCH_CLASS = ".iw-tabs-switch",
      DEFAULT_TAB_CLASS = ".iw-tab";
class Tabs {
    constructor(widget, options) {
        this.widget = widget;

        this.switcherClassName = DEFAULT_TABS_SWITCH_CLASS;
        this.tabClassName = DEFAULT_TAB_CLASS;
        if (options) {
            this.switcherClassName = options.switcherClassName || DEFAULT_TABS_SWITCH_CLASS;
            this.tabClassName = options.tabClassName || DEFAULT_TAB_CLASS;
        }
        if (options.callbackAfterCreate) {
            options.callbackAfterCreate(widget);
        }
    }
    tabLoad(dataTab) {
        let beforeLoadEvent = new Event("tabs:beforeload", {"bubbles":true});
        this.widget.dispatchEvent(beforeLoadEvent);

        [].slice.call(this.widget.querySelectorAll(`.${this.switcherClassName}`)).forEach(function (widget) {
            widget.classList.remove("current");
        });
        this.widget.querySelector(`.${this.switcherClassName}[data-tab="${dataTab}"]`).classList.add("current");
        [].slice.call(this.widget.querySelectorAll(`.${this.tabClassName}`)).forEach(function (widget) {
            widget.classList.remove("active");
        });
        this.widget.querySelector(`.${dataTab}`).classList.add("active");

        let loadEvent = new Event("tabs:loaded", {
            bubbles: true
        });
        this.widget.dispatchEvent(loadEvent);
    };
}
module.exports = function (args) {
    if (args.widget) {

        [].slice.call(args.widget).forEach(function (widget) {

            let createdObject = new Tabs(widget, args.options);

           createdObject.tabLoad(widget.querySelector(`.${createdObject.switcherClassName}.current`).dataset.tab);

            [].slice.call(widget.querySelectorAll(`.${createdObject.switcherClassName}`)).forEach(function (switcher) {
                switcher.addEventListener("click", function () {
                    createdObject.tabLoad(switcher.dataset.tab);

                    let clickEvent = new Event("tabs:click", {"bubbles":true});
                    createdObject.widget.dispatchEvent(clickEvent);
                });
            });
        });

    } else {
        throw new Error(`tabs.init: init new tabs failed - no object specified. specify $ object in arguments like: {widget: document.querySelectorAll("selector")}`);
    }
};