"use strict";

var opts = {
    defaultModuleDir: "/files/frontend/scripts/modules/jquery.ivideo",
    themeName: "default",
    videoHeight: 315,
    videoWidth: 560
};
var $ = require("../jquery");
module.exports = function (selector) {
    function buildPopup(inlineHtml) {
        function popupClose(popup) {
            popup.find(".video-wrap").animate({ opacity: 0 }, 200, function () {
                $("body").css("overflow", "auto");
                popup.css("display", "none");
                popup.remove();
            });
        }
        var popupObject = $('<div class="ivideo-popup_theme-' + opts.themeName + '"><div class="video-wrap"><div class="video-container"></div></div></div>').prependTo("html");
        $(inlineHtml).appendTo(popupObject.find(".video-container"));
        $("body").css("overflow", "hidden");
        popupObject.find(".video-wrap").animate({ opacity: 1 }, 400, function () {
            popupObject.on("click", function () {
                popupClose(popupObject);
            });
            popupObject.find(".video-wrap").on("click", function (evt) {
                evt.stopPropagation();
            });
            var OpenEvent = new Event("ivideo:popup", { bubbles: true });
            document.dispatchEvent(OpenEvent);
        });
    }
    $.each($(selector), function () {
        var thisVideoObject = $(this),
            inlineVideoHtml = "";
        thisVideoObject.addClass("ivideo_theme-" + opts.themeName);
        thisVideoObject.sourcetype = thisVideoObject.data("sourcetype");
        thisVideoObject.video = thisVideoObject.data("video");
        switch (thisVideoObject.sourcetype) {
            case "youtube":
                {
                    inlineVideoHtml = '<iframe width="' + opts.videoWidth + '" height="' + opts.videoHeight + '" src="//www.youtube.com/embed/' + thisVideoObject.video + '" frameborder="0" allowfullscreen></iframe>';
                    break;
                }
            case "server-mp4":
                {
                    inlineVideoHtml = '<video autoplay="autoplay" width="' + opts.videoWidth + '" height="' + opts.videoHeight + '" controls="controls" >' + '<source src="' + thisVideoObject.video + '" type=\'video/mp4; codecs="avc1.42E01E, mp4a.40.2"\'>' + '</video>';
                    break;
                }
            case "server-mov":
                {
                    inlineVideoHtml = '<video autoplay="autoplay" width="' + opts.videoWidth + '" height="' + opts.videoHeight + '" controls="controls" >' + '<source src="' + thisVideoObject.video + '" >' + '</video>';
                    break;
                }
        }
        thisVideoObject.on("click", function () {
            buildPopup(inlineVideoHtml);
            if (opts.onclickYandexGoal != "") {
                //    rosgaz.yandex.counter.reachGoal(opts.onclickYandexGoal);
            }
        });
    });
};