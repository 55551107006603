"use strict";

module.exports = function (id) {
    return {
        reachGoal: function reachGoal(goal, params) {
            return new Promise(function (resolve, reject) {
                try {
                    window["yaCounter" + id].reachGoal(goal, params || {}, function () {
                        resolve({ goal: goal, status: "OK" });
                    });
                } catch (e) {
                    reject(e);
                }
            });
        }
    };
};