let $ = require("../jquery");
module.exports = {
    map: {},
    geocoder: {},
    objectsLoaded: {},
    markersVisible:[],
    stylers : [
            {
                "stylers": [
                    { "saturation": -100 },
                    { "lightness": 28 }
                ]
            },
            {
                "featureType": "road",
                "stylers": [
                    { "gamma": 0.37 }
                ]
            }
     ],
    addMarker(jsonobj) {
        let parentObj = this;
        let location;
        if (jsonobj.Lng_Lat) {
            location = new google.maps.LatLng(jsonobj.Lng_Lat[0], jsonobj.Lng_Lat[1]);
        } else {

        }
        parentObj.markersVisible.push(new google.maps.Marker({
            position: location,
            map: parentObj.map,
            icon: parentObj.markerImage,
            clickable: false,
            title: jsonobj.address
        }));
    },
    markerImage : {

    },
    markerCluster: {},
    init(map_id, json, posX, posY, zoom) {
        let parentObj = this;
        $.ajax({
            url: json,
            type: 'get',
            dataType: "text",
            success: function(data){
                parentObj.objectsLoaded = $.parseJSON(data).markers;
                parentObj.markerImage = {
                    url : '/app/build/template/spbr-b_google-marker.png',
                    size : new google.maps.Size(46, 48),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(16, 47)
                };

                let marker_pos = new google.maps.LatLng(posX, posY), mapOptions = {
                    center: marker_pos,
                    zoom: zoom,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    scrollwheel: false,
                    disableDefaultUI: true
                };

                parentObj.geocoder = new google.maps.Geocoder();
                parentObj.map = new google.maps.Map(document.getElementById(map_id), mapOptions);
                parentObj.map.setOptions({styles: parentObj.stylers});

                parentObj.build();
            }
        });
    },
    getLngFromAdresses() {
        let parentObj = this;
        let index = 0;
        $.each(parentObj.objectsLoaded, function(){
            let thisObj = this;
            if (!thisObj.Lng_Lat) {
                setTimeout(function(){
                    parentObj.geocoder.geocode(
                        { 'address': thisObj.address},
                        function(results, status) {
                            if (status == google.maps.GeocoderStatus.OK) {
                                let resultLat = results[0].geometry.location.lat(),
                                    resultLng = results[0].geometry.location.lng();
                                thisObj.Lng_Lat = [resultLng,resultLat];
                            } else {
                            }
                        });
                },index*2000);
            } else {
                index-=1;
            }
            index+=1;
        });
    },
    build() {
        let parentObj = this;
        $.each(parentObj.markersVisible, function(){
            this.setMap(null);
        });

        parentObj.markersVisible = [];

        for (let i = 0; i < parentObj.objectsLoaded.length; i++){
            parentObj.addMarker(
                {
                    "Lng_Lat":[parentObj.objectsLoaded[i].Lng_Lat[1],parentObj.objectsLoaded[i].Lng_Lat[0]]
                }
            );
        }
    }
};
